import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FragmentMapper} from 'app/fragment/core/fragment-mapper';
import {ClauseFragment, FigureFragment, Fragment, FragmentType, SectionFragment} from 'app/fragment/types';
import {CarsAction} from 'app/permissions/types/permissions';
import {FragmentService} from 'app/services/fragment.service';
import {UUID} from 'app/utils/uuid';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'cars-alt-image',
  templateUrl: './alt-image.component.html',
  styleUrls: ['./alt-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AltImageComponent implements OnInit, OnDestroy {
  @Input() imageFragment: FigureFragment;

  public readonly CarsAction: typeof CarsAction = CarsAction;

  public documentId: UUID;
  public imageFragmentCopy: FigureFragment;

  public textAreaText: string;
  public textAreaModelChanged: Subject<string> = new Subject();

  private subscriptions: Subscription[] = [];

  constructor(private fragmentService: FragmentService, private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.documentId = this.imageFragment.documentId;

    this.createFigureFragmentClone();

    this.subscriptions.push(
      this.textAreaModelChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((newText: string) => {
        this.textAreaText = newText;
        this.updateAltText(newText);
      }),
      this.fragmentService.onUpdate(
        () => this.createFigureFragmentClone(),
        (frag) => {
          return (
            this.imageFragment.id.equals(frag.id) ||
            this.imageFragment.parent.id.equals(frag.id) ||
            this.imageFragment.parent.parent.id.equals(frag.id)
          );
        }
      ),
      this.fragmentService.onSelection(
        () => this.createFigureFragmentClone(),
        (frag: Fragment) => this.imageFragment.parent.id.equals(frag?.findAncestorWithType(FragmentType.CLAUSE)?.id)
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.splice(0).forEach((s) => s.unsubscribe());
  }

  /**
   * Creates a clone of the image fragment so that changing properties dows not alter the real fragment.
   * We set landscape to be false, and we set the uploadProperties to be null so that the image displays at 100% width in the sidebar.
   * Also creates a dummy parent clause as null clauses cause issues when displaying the figure.
   */
  private createFigureFragmentClone(): void {
    this.imageFragmentCopy = FragmentMapper.clone(this.imageFragment) as FigureFragment;
    this.imageFragmentCopy.landscape = false;
    this.imageFragmentCopy.uploadProperties = null;

    const imageClause = this.imageFragment.findAncestorWithType(FragmentType.CLAUSE) as ClauseFragment;
    const imageClauseCopy: ClauseFragment = ClauseFragment.empty(imageClause.clauseType);
    imageClauseCopy.children.push(this.imageFragmentCopy);

    const imageSection = imageClause.findAncestorWithType(FragmentType.SECTION) as SectionFragment;
    const imageSectionCopy: SectionFragment = SectionFragment.empty(imageSection.sectionType, '');
    imageSectionCopy.children.push(imageClauseCopy);

    this.textAreaText = this.imageFragment.altText;

    this.cdr.markForCheck();
  }

  /**
   * Update and persist the figure fragment with the new alt text value.
   *
   * @param altText The new alt text value to persist
   */
  private updateAltText(altText: string): void {
    this.imageFragment.altText = altText;
    this.fragmentService.update(this.imageFragment);
  }
}
